import React, { useState } from 'react'
import { Link } from 'gatsby'
import Sidebar from './Menu'
import logo from '../../public/logos/logo.svg'

const Menu = () => {
  const [active, setActive] = useState()

  const toggleActive = () => {
    let isActive = active ? false : true
    setActive(isActive)
  }

  return (
    <>
      <header
        className="box"
        style={{
          background: '#FFF',
          border: 'none',
          'box-shadow': 'none',
          'z-index': '1000',
          'margin-bottom': '0px',
        }}
      >
        <nav className="level is-mobile">
          <div className="level-left">
            <Link to="/" style={{border: "none"}}>
              <img
                src={logo}
                alt="Move Through Trauma"
                style={{ width: 200 }}
              />
            </Link>
          </div>
          <div className="level-right">
            <button
              className={active ? 'close' : 'open'}
              aria-label={active ? 'close menu' : 'open menu'}
              onClick={() => toggleActive()}
              style={{ background: 'none', border: 'none' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="21">
                <g className="lines">
                  <path
                    className="top-line"
                    d="M 0 2 L 35 2"
                    fill="transparent"
                    strokeWidth="3"
                  ></path>
                  <path
                    className="middle-line"
                    d="M 0 11 L 35 11"
                    fill="transparent"
                    strokeWidth="3"
                  ></path>
                  <path
                    className="bottom-line"
                    d="M 0 20 L 35 20"
                    fill="transparent"
                    strokeWidth="3"
                  ></path>
                </g>
                <g className="cross">
                  <path
                    className="top-line"
                    d="M 0 2 L 35 2"
                    fill="transparent"
                    strokeWidth="3"
                  ></path>
                  <path
                    className="bottom-line"
                    d="M 0 20 L 35 20"
                    fill="transparent"
                    strokeWidth="3"
                  ></path>
                </g>
              </svg>
              <span style={{ 'font-size': '1.5rem' }}>
                {' '}
                {active ? 'Close' : 'Menu'}
              </span>
            </button>
          </div>
        </nav>
      </header>
      <Sidebar active={active} setActive={setActive} />
    </>
  )
}

export default Menu
