import React from 'react'
import { Link } from 'gatsby'

const Menu = ({ active, setActive }) => (
  <div className={ active ? "modal menu is-active in" : "modal menu is-active out" }>
    <div className="modal-background"></div>
    <ul className="modal-content">
      <li className="has-text-centered">
      <Link
      to="/"
      activeClassName="active"
      className="link is-info"
    >Home</Link>
      </li>
      <li className="has-text-centered">
      <Link
      to="/training"
      activeClassName="active"
      className="link is-info"
    >Training</Link>
      </li>
      <li className="has-text-centered">
      <Link
      to="/1-to-1-sessions"
      activeClassName="active"
      className="link is-info"
    >1 to 1 Sessions</Link>
      </li>
      <li className="has-text-centered">
      <Link
      to="/blog"
      activeClassName="active"
      className="link is-info"
    >Blog</Link>
      </li>
      <li className="has-text-centered">
      <Link
      to="/about"
      activeClassName="active"
      className="link is-info"
    >About</Link>
      </li>
      <li className="has-text-centered">
      <Link
      to="/contact"
      activeClassName="active"
      className="link is-info"
    >Contact</Link>
      </li>
    </ul>
  </div>
)

export default Menu
