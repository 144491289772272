import React from 'react'
import { Link } from 'gatsby'
import logo from '../../public/logos/logo-white.svg'

const Footer = () => (
  <>
 
  <footer className="footer" style={{background: "#011936", "padding-bottom": "2rem"}}>
    <div className="container">
    <div className="columns">
    <div className="column is-6">
    <Link to="/" style={{border: "none"}}>
              <img
                src={logo}
                alt="Move Through Trauma"
                style={{ width: 200 }}
              />
            </Link>
    </div>
          


        
          <div className="column is-3"><ul>
<li><Link to="/">Home</Link></li>
<li><Link to="/about">About</Link></li>
<li><Link to="/contact">Contact</Link></li>
          </ul></div>
          <div className="column is-3">
          <ul>
          <li><Link to="/blog">Blog</Link></li>
<li><Link to="/training">Training</Link></li>
<li><Link to="/1-to-1-sessions">1 to 1 Sessions</Link></li>
          </ul>
          </div>
  
      </div>
      
    </div>
    <p className="has-text-centered" style={{color: "rgba(255, 255, 255, 0.8)", "font-size": "0.9rem", "margin-top": "4rem"}}>© 2019 Move Through Trauma</p>

  </footer>
  </>
)

export default Footer
