import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Navbar from './Navbar'
import Footer from '../components/Footer'
import "../styles/global.scss"

const Template = ({ children }) => {
  return (
    <div>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script src="https://cdn.embedly.com/widgets/platform.js" type="text/javascript"/>
      </Helmet>

        <>
            <Navbar />
            
            {children}
            
          <Footer />
        </>
    </div>
  )
}

export default Template
